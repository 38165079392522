<template>
	<form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-grid">
		<Toast />
		<ConfirmDialog></ConfirmDialog>
		
		<div class="grid p-fluid stickBar">
			<div class="col-12">
				<div class="card card-w-title" id="stickBar">
					<Menubar :model="nestedMenuitems">
						<template #end>
							<div class="flex justify-content-end flex-wrap card-container green-container">
								<div
									class="flex align-items-center justify-content-center font-bold text-gray-500 text-xl m-2">
									{{ mainData.seriNo }}
								</div>
								<div class="flex align-items-center justify-content-center m-2">
									<Button class="p-button-rounded p-button-danger mr-0 mb-0"
										v-if="$router.options.history.state.back != null" icon="pi pi-times"
										@click="$router.go(-1)" />
								</div>
							</div>
						</template>
					</Menubar>
				</div>
			</div>
		</div>
		
		<div class="grid">
			<div class="col-12">
				<div class="card">
					<TabView v-model:activeIndex="tabviewActiveIndex" :scrollable="true" >
						<!-- GENEL -->
						<TabPanel>
							<template #header>
								<div class="blue-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-bars"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">Genel</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">TEZGAH</span>
										</div>
									</div>
								</div>
							</template>

							<div class="grid">
								<div class="col-12">
									<div class="card">
										<div class="grid">
											<div class="col-6">
												<div class="field p-fluid">
													<EntityLookup id="firma" v-model="mainData.firmaName" ref="entity_firma" label="Firma"
														entityName="account" nameField="name" :state="true" :disabled="isDisabled"
														@itemSelected="firmaSelected = $event" @itemCleared="firmaSelected = null">
													</EntityLookup>
												</div>
											</div>
											<div class="col-4">
												<div class="field p-fluid">
													<EntityLookupPopup id="urun" v-model="mainData.urunName" ref="entity_urun" label="Ürün"
														entityName="product" nameField="name" :state="true" :disabled="isDisabled"
														@itemSelected="urunSelected = $event" @itemCleared="urunSelected = null"
														secondNameField="productnumber"
														:CrmDataTable_options="urun_CrmDataTable_options">
													</EntityLookupPopup>
												</div>
											</div>
											<div class="col-2">
												<div class="field p-fluid">
													<label for="seriNo">Seri No</label>
													<InputText id="seriNo" type="text" v-model="mainData.seriNo" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-6">
												<div class="field p-fluid">
													<EntityLookup id="musteriFaturaName" v-model="mainData.musteriFaturaName" ref="entity_musteriFatura"
														label="Müşteri Fatura" entityName="account" nameField="name" :state="true" :disabled="isDisabled"
														@itemSelected="musteriFaturaSelected = $event"
														@itemCleared="musteriFaturaSelected = null">
													</EntityLookup>
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<EntityLookup id="teklifNo" v-model="mainData.teklifNoName" ref="entity_teklifNo"
														label="Teklif No" entityName="quote" nameField="name" :state="true" :disabled="isDisabled"
														@itemSelected="teklifNoSelected = $event"
														@itemCleared="teklifNoSelected = null">
													</EntityLookup>
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<EntityLookup id="siparisNo" v-model="mainData.siparisNoName" ref="entity_siparisNo"
														label="Sipariş No" entityName="salesorder" nameField="name" :state="true" :disabled="isDisabled"
														@itemSelected="siparisNoSelected = $event"
														@itemCleared="siparisNoSelected = null">
													</EntityLookup>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="card">
										<h5>GENEL</h5>
										<div class="grid">
											<div class="col-3">
												<div class="field p-fluid">
													<label for="frmNo">FRM No</label>
													<InputText id="frmNo" type="text" v-model="mainData.frmNo" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="poNo">PO No</label>
													<InputText id="poNo" type="text" v-model="mainData.poNo" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="modelYil">Model (Yıl)</label>
													<InputText id="modelYil" type="text" v-model="mainData.modelYil" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="kurulumTarihi">Kurulum Tarihi</label>
													<Calendar showButtonBar id="kurulumTarihi" v-model="mainData.kurulumTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="urunmarka">Ürün Marka</label>
													<Dropdown id="urunmarka" v-model="mainData.urunMarka" :options="SM_urunmarka" optionLabel="Value"
														optionValue="AttributeValue" placeholder="Ürün Marka" :showClear="true" :filter="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="durumAciklamasi">Durum Açıklaması</label>
													<Dropdown id="durumAciklamasi" v-model="mainData.durumAciklamasi" :options="SM_durumAciklamasi" optionLabel="Value" optionValue="AttributeValue" placeholder="Durum Açıklaması" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="tezgahTuru">Tezgah Türü</label>
													<Dropdown id="tezgahTuru" v-model="mainData.tezgahTuru" :options="SM_tezgahTuru" optionLabel="Value" optionValue="AttributeValue" placeholder="Tezgah Türü" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="siparisTarihi">Sipariş Tarihi</label>
													<Calendar showButtonBar id="siparisTarihi" v-model="mainData.siparisTarihiSip" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="garantiTarihi">Garanti Tarihi</label>
													<Calendar showButtonBar id="garantiTarihi" v-model="mainData.garantiBitisTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="durumNotu">Durum Notu</label>
													<Dropdown id="durumNotu" v-model="mainData.durumNotu" :options="SM_durumNotu" optionLabel="Value" optionValue="AttributeValue" placeholder="Durum Notu" :showClear="true" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="durumBolge">Durum Bölge</label>
													<Dropdown id="durumBolge" v-model="mainData.durumBolge" :options="SM_durumBolge" optionLabel="Value" optionValue="AttributeValue" placeholder="Durum Bölge" :showClear="true" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="urunTipi">Ürün Tipi</label>
													<Dropdown id="urunTipi" v-model="mainData.urunTipi" :options="SM_UrunTipi" optionLabel="Value" optionValue="AttributeValue" placeholder="Ürün Tipi" :filter="true" :disabled="true" />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="card">
										<h5>TEKNİK BİLGİLER</h5>
										<div class="grid">
											<div class="col-3">
												<div class="field p-fluid">
													<label for="kontrolUnitesi">Kontrol Ünitesi</label>
													<InputText id="kontrolUnitesi" type="text" v-model="mainData.kontrolUnitesi" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="ncModel">NC Model</label>
													<InputText id="ncModel" type="text" v-model="mainData.ncModel" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="ncSeriNo">NC Seri No</label>
													<InputText id="ncSeriNo" type="text" v-model="mainData.ncSeriNo" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="ladder">Ladder</label>
													<InputText id="ladder" type="text" v-model="mainData.ladder" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="mainA">Main A</label>
													<InputText id="mainA" type="text" v-model="mainData.mainA" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="mainB">Main B</label>
													<InputText id="mainB" type="text" v-model="mainData.mainB" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="hdProductId">HD Product ID</label>
													<InputText id="hdProductId" type="text" v-model="mainData.hdProductId" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="magazinTutucuTipi">Magazin Tutucu Tipi</label>
													<Dropdown id="magazinTutucuTipi" v-model="mainData.magazinTutucuTipi" :options="SM_magazinTutucuTipi" optionLabel="Value" optionValue="AttributeValue" placeholder="Magazin Tutucu Tipi" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="mensei">Menşei</label>
													<Dropdown id="mensei" v-model="mainData.menseiId" :options="SM_mensei" optionLabel="Value" optionValue="AttributeValue" placeholder="Menşei" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="mfdNo">MFD No</label>
													<InputText id="mfdNo" type="text" v-model="mainData.mfdNo" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="lazerModel">Lazer Model</label>
													<InputText id="lazerModel" type="text" v-model="mainData.lazerModel" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="chillerModel">Chiller Model</label>
													<InputText id="chillerModel" type="text" v-model="mainData.chillerModel" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="chillerSn">Chiller SN</label>
													<InputText id="chillerSn" type="text" v-model="mainData.chillerSn" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="system">System</label>
													<InputText id="system" type="text" v-model="mainData.system_" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="rev">Rev</label>
													<InputText id="rev" type="text" v-model="mainData.rev" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="rev">Backup</label><br/>
													<InputSwitch id="backup" v-model="mainData.backup_" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-12">
												<div class="field p-fluid">
													<label for="garantiEkBilgiler">Garanti Ek Bilgiler</label>
													<Textarea id="garantiEkBilgiler" rows="2" :autoResize="false" v-model="mainData.garantiEkBilgiler" :disabled="isDisabled" />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="card">
										<h5>TEZGAH TAKİBİ</h5>
										<div class="grid">
											<div class="col-3">
												<div class="field p-fluid">
													<label for="yuklemeyeri">Yükleme Yeri</label>
													<Dropdown id="yuklemeyeri" v-model="mainData.yuklemeYeri" :options="SM_yuklemeyeri"
														optionLabel="Value" optionValue="AttributeValue" placeholder="Yükleme Yeri"
														:showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="ihracatizni">İhracat İzni</label>
													<Dropdown id="ihracatizni" v-model="mainData.ihracatIzni" :options="SM_ihracatizni" optionLabel="Value" optionValue="AttributeValue" placeholder="İhracat İzni" :showClear="true" :disabled="isDisabled" />
													
												</div>
											</div>
											<div class="col-3" v-if="mainData.ihracatIzni != 930710000">
												<div class="field p-fluid">
													<label for="ihracatIzniDurum">İhracat İzni Durum</label>
													<Dropdown id="ihracatIzniDurum" v-model="mainData.ihracatIzniDurum" :options="SM_ihracatIzniDurum" optionLabel="Value" optionValue="AttributeValue" placeholder="İhracat İzni Durum" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3" v-if="mainData.ihracatIzni == 930710000">
												<div class="field p-fluid">
													<label for="silahYazisi">Silah Yazısı</label>
													<Calendar showButtonBar id="silahYazisi" v-model="mainData.silahYazisi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3" v-if="mainData.ihracatIzni != 930710000">
												<div class="field p-fluid">
													<label for="ihracatIzniGonderme">İhr İzni Gönderme</label>
													<Calendar showButtonBar id="ihracatIzniGonderme" v-model="mainData.ihracatIzniGonderme" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3" v-if="mainData.ihracatIzni != 930710000">
												<div class="field p-fluid">
													<label for="ihracatIzniOnay">İhr İzni Onay</label>
													<Calendar showButtonBar id="ihracatIzniOnay" v-model="mainData.ihracatIzniOnay" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="card">
										<h5>ÖDEME BİLGİLERİ</h5>
										<div class="grid">
											<div class="col-3">
												<div class="field p-fluid">
													<label for="odeme">Ödeme</label>
													<Dropdown id="odeme" v-model="mainData.odeme" :options="SM_odeme" optionLabel="Value" optionValue="AttributeValue" placeholder="Ödeme" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="leasing">Leasing</label>
													<Dropdown id="leasing" v-model="mainData.leasing" :options="SM_leasing" optionLabel="Value" optionValue="AttributeValue" placeholder="Leasing" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="leasingMusteriIlgili">Leasing/Müşteri İlgili</label>
													<InputText id="leasingMusteriIlgili" type="text" v-model="mainData.leasingMusteriIlgili" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="odemeYeri">Ödeme Yeri</label>
													<InputText id="odemeYeri" type="text" v-model="mainData.odemeYeri" :disabled="isDisabled" />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="card">
										<h5>ÖDEME DETAYLARI</h5>
										<div class="grid">
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="pesinatOdendi">Peşinat Ödendi</label><br/>
													<Checkbox id="pesinatOdendi" v-model="mainData.pesinatOdendi" :binary="true" class="mt-2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="odemearaci1">Ödeme Aracı (1)</label>
													<Dropdown id="odemearaci1" v-model="mainData.odemearaci1" :options="SM_odemearaci1" optionLabel="Value" optionValue="AttributeValue" placeholder="Ödeme Aracı (1)" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="odemeTarihi1">Ödeme/Vade Tarihi 1</label>
													<Calendar showButtonBar id="odemeTarihi1" v-model="mainData.odemeTarihi1" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="odemeTutari1">Ödeme Tutarı 1</label>
													<InputNumber id="odemeTutari1" v-model="mainData.odemeTutari1" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="paraBirimi1">Para Birimi (1)</label>
													<Dropdown id="paraBirimi1" v-model="mainData.paraBirimi1" :options="SM_paraBirimi1" optionLabel="Value" optionValue="AttributeValue" placeholder="Para Birimi (1)" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
										</div>

										<div class="grid">
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="araOdeme">Ara Ödeme</label><br/>
													<Checkbox id="araOdeme" v-model="mainData.araOdeme" :binary="true" class="mt-2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="odemearaci2">Ödeme Aracı (2)</label>
													<Dropdown id="odemearaci2" v-model="mainData.odemearaci2" :options="SM_odemearaci2" optionLabel="Value" optionValue="AttributeValue" placeholder="Ödeme Aracı (2)" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="odemeTarihi2">Ödeme/Vade Tarihi 2</label>
													<Calendar showButtonBar id="odemeTarihi2" v-model="mainData.odemeTarihi2" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="odemeTutari2">Ödeme Tutarı 2</label>
													<InputNumber id="odemeTutari2" v-model="mainData.odemeTutari2" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="paraBirimi2">Para Birimi (2))</label>
													<Dropdown id="paraBirimi2" v-model="mainData.paraBirimi2" :options="SM_paraBirimi2" optionLabel="Value" optionValue="AttributeValue" placeholder="Para Birimi (2)" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
										</div>
										<div class="grid">
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="devirdeOdeme">Devirde Ödeme</label><br/>
													<Checkbox id="devirdeOdeme" v-model="mainData.devirdeOdeme" :binary="true" class="mt-2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="odemearaci3">Ödeme Aracı (3)</label>
													<Dropdown id="odemearaci3" v-model="mainData.odemearaci3" :options="SM_odemearaci3" optionLabel="Value" optionValue="AttributeValue" placeholder="Ödeme Aracı (3)" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="odemeTarihi3">Ödeme/Vade Tarihi 3</label>
													<Calendar showButtonBar id="odemeTarihi3" v-model="mainData.odemeTarihi3" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="odemeTutari3">Ödeme Tutarı 3</label>
													<InputNumber id="odemeTutari3" v-model="mainData.odemeTutari3" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="paraBirimi3">Para Birimi (3))</label>
													<Dropdown id="paraBirimi3" v-model="mainData.paraBirimi3" :options="SM_paraBirimi3" optionLabel="Value" optionValue="AttributeValue" placeholder="Para Birimi (3)" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
										</div>
										<div class="grid">
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="tumuOdendi">Tümü Ödendi</label><br/>
													<Checkbox id="tumuOdendi" v-model="mainData.tumuOdendi" :binary="true" class="mt-2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="odemearaci5">Ödeme Aracı (5)</label>
													<Dropdown id="odemearaci3" v-model="mainData.odemearaci5" :options="SM_odemearaci5" optionLabel="Value" optionValue="AttributeValue" placeholder="Ödeme Aracı (5)" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="odemeTarihi5">Ödeme/Vade Tarihi 5</label>
													<Calendar showButtonBar id="odemeTarihi5" v-model="mainData.odemeTarihi5" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="kabulOdemesiVarsaTutari">Kabul Ödemesi Varsa Tutarı</label>
													<InputNumber id="kabulOdemesiVarsaTutari" v-model="mainData.kabulOdemesiVarsaTutari" mode="decimal" locale="tr-TR" :minFractionDigits="2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="paraBirimi5">Para Birimi (5))</label>
													<Dropdown id="paraBirimi5" v-model="mainData.paraBirimi5" :options="SM_paraBirimi5" optionLabel="Value" optionValue="AttributeValue" placeholder="Para Birimi (5)" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
										</div>

										<div class="grid">
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="siparisMetniGeldi">Sipariş Metni Geldi</label><br/>
													<Checkbox id="siparisMetniGeldi" v-model="mainData.siparisMetniGeldi" :binary="true" class="mt-2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="odemeTarihi4">Ödeme Tarihi 4</label>
													<Calendar showButtonBar id="odemeTarihi4" v-model="mainData.odemeTarihi4" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="paraBirimi4">Para Birimi (4))</label>
													<Dropdown id="paraBirimi4" v-model="mainData.paraBirimi4" :options="SM_paraBirimi5" optionLabel="Value" optionValue="AttributeValue" placeholder="Para Birimi (4)" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
										</div>

										<div class="grid">
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="akreditifAcildi">Akreditif Açıldı</label><br/>
													<Checkbox id="akreditifAcildi" v-model="mainData.akreditifAcildi" :binary="true" class="mt-2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
												</div>
											</div>
											<div class="col-1" style="width: 20%;">
												<div class="field p-fluid">
													<label for="akreditifTarihi">Akreditif Tarihi</label>
													<Calendar showButtonBar id="akreditifTarihi" v-model="mainData.akreditifTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-1" style="width: 40%;">
												<div class="field p-fluid">
													
												</div>
											</div>
										</div>
										<div class="grid">
											<div class="col-12">
												<div class="field p-fluid">
													<label for="odemeAciklama">Ödeme Açıklama</label>
													<Textarea id="olculer" rows="3" :autoResize="true" v-model="mainData.odemeAciklama" placeholder="Ödeme Açıklama" :disabled="isDisabled" />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="card">
										<h5>YÜKLEME</h5>
										<div class="grid">
											<div class="col-3">
												<div class="field p-fluid">
													<label for="sozlesmedekiYukleme">Sözleşmedeki Yükleme</label>
													<InputText id="sozlesmedekiYukleme" type="text" v-model="mainData.sozlesmedekiYukleme" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="sozlesmeYuklemeTarihi">Sözleşme Yükleme Tarihi</label>
													<Calendar showButtonBar id="sozlesmeYuklemeTarihi" v-model="mainData.sozlesmeYuklemeTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="gerceklesenYuklemeTarihi">Gerçekleşen Yükleme Tarihi</label>
													<Calendar showButtonBar id="gerceklesenYuklemeTarihi" v-model="mainData.gerceklesenYuklemeTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="gerceklesenVarisTarihi">Gerçekleşen Varış Tarihi</label>
													<Calendar showButtonBar id="gerceklesenVarisTarihi" v-model="mainData.gerceklesenVarisTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="planlananYukleme">Planlanan Yükleme</label>
													<Calendar showButtonBar id="planlananYukleme" v-model="mainData.planlananYukleme" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="planlananVaris">Planlanan Varış</label>
													<Calendar showButtonBar id="planlananVaris" v-model="mainData.planlananVaris" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="varisGumrugu">Varış Gümrüğü</label>
													<Dropdown id="varisGumrugu" v-model="mainData.varisGumrugu" :options="SM_varisGumrugu" optionLabel="Value" optionValue="AttributeValue" placeholder="Varış Gümrüğü" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="gumrukCikisTarihi">Gümrük Çıkış Tarihi</label>
													<Calendar showButtonBar id="gumrukCikisTarihi" v-model="mainData.gumrukCikisTarihi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="card">
										<h5>ÖLÇÜLER</h5>
										<div class="grid">
											<div class="col-12">
												<div class="field p-fluid">
													<label for="olculer">Ölçüler</label>
													<Textarea id="olculer" rows="3" :autoResize="true" v-model="mainData.olculer" placeholder="Ölçüler" :disabled="isDisabled" />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="card">
										<h5>DETAYLAR</h5>
										<div class="grid">
											<div class="col-6">
												<div class="field p-fluid">
													<label for="aciklama">Açıklama</label>
													<Textarea id="aciklama" rows="3" :autoResize="false" v-model="mainData.aciklama" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-6">
												<div class="field p-fluid">
													<label for="notlar">Notlar</label>
													<Textarea id="notlar" rows="3" :autoResize="false" v-model="mainData.notlar" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="sayfaGuncelleme">Sayfa Güncelleme</label>
													<Calendar showButtonBar id="sayfaGuncelleme" v-model="mainData.sayfaGuncelleme" autocomplete="off" :showIcon="true" showTime hourFormat="24" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="tezgahinBulunduguYer">Tezgahın Bulunduğu Yer</label>
													<InputText id="tezgahinBulunduguYer" type="text" v-model="mainData.tezgahinBulunduguYer" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="bolge">Bölge</label>
													<Dropdown id="bolge" v-model="mainData.bolge" :options="SM_bolge" optionLabel="Value" optionValue="AttributeValue" placeholder="Bölge" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="tavsiyeEdilenSatisFiyati">Tavsiye Edilen Satış Fiyatı</label>
													<InputText id="tavsiyeEdilenSatisFiyati" v-model="mainData.tavsiyeEdilenSatisFiyati" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="statu">Statü</label>
													<Dropdown id="statu" v-model="mainData.statu" :options="SM_statu" optionLabel="Value" optionValue="AttributeValue" placeholder="Statü" :disabled="isDisabled"  />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="turu">Türü</label>
													<Dropdown id="turu" v-model="mainData.turu" :options="SM_turu" optionLabel="Value" optionValue="AttributeValue" placeholder="Türü" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="ikinciElDurumu">2. El Durumu</label>
													<Dropdown id="ikinciElDurumu" v-model="mainData.ikinciElDurumu" :options="SM_ikinciElDurumu" optionLabel="Value" optionValue="AttributeValue" placeholder="2. El Durumu" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="tezgahTipi">Tezgah Tipi</label>
													<Dropdown id="tezgahTipi" v-model="mainData.tezgahTipi" :options="SM_tezgahTipi" optionLabel="Value" optionValue="AttributeValue" placeholder="Tezgah Tipi" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="tamamlandimi">Tamamlandı mı?</label><br/>
													<Checkbox id="tamamlandimi" v-model="mainData.tamamlandimi" :binary="true" class="mt-2" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="musteriyeTeslimEdilecekTarih">Müşteriye Teslim Edilecek Tarih</label>
													<Calendar showButtonBar id="musteriyeTeslimEdilecekTarih" v-model="mainData.musteriyeTeslimEdilecekTarih" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="tesvikVarmi">Teşvik Varmi</label>
													<Dropdown id="tesvikVarmi" v-model="mainData.tesvikVarmi" :options="SM_tesvikVarmi" optionLabel="Value" optionValue="AttributeValue" placeholder="Teşvik Varmi" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="col-12">
									<div class="card">
										<h5>BAKIM SÖZLEŞMELERİ</h5>
										<div class="grid">
											<div class="col-3">
												<div class="field p-fluid">
													<label for="bakimBitisi">Bakım Bitişi</label>
													<Calendar showButtonBar id="bakimBitisi" v-model="mainData.bakimBitisi" autocomplete="off" :showIcon="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="bakimUcreti">Bakım Ücreti</label>
													<InputNumber id="bakimUcreti" v-model="mainData.bakimUcreti" mode="decimal" locale="tr-TR" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="kullandigiYag">Kullandığı Yağ</label>
													<Dropdown id="kullandigiYag" v-model="mainData.kullandigiYag" :options="SM_kullandigiYag" optionLabel="Value" optionValue="AttributeValue" placeholder="Kullandığı Yağ" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="bakimYapildimi">Bakım Yapıldı mı?</label>
													<Dropdown id="bakimYapildimi" v-model="mainData.bakimYapildimi" :options="SM_bakimYapildimi" optionLabel="Value" optionValue="AttributeValue" placeholder="Bakım Yapıldı mı?" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
											<div class="col-3">
												<div class="field p-fluid">
													<label for="bakimSozlesmesiVarmi">Bakım Sözleşmesi Var mı?</label>
													<Dropdown id="bakimSozlesmesiVarmi" v-model="mainData.bakimSozlesmesiVarmi" :options="SM_bakimSozlesmesiVarmi" optionLabel="Value" optionValue="AttributeValue" placeholder="Bakım Sözleşmesi Var mı?" :showClear="true" :disabled="isDisabled" />
												</div>
											</div>
										</div>
									</div>
								</div>

							</div>
						</TabPanel>

						<!-- TEZGAH OPSİYONLAR -->
						<TabPanel>
							<template #header>
								<div class="purple-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-bars"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">TEZGAH OPSİYONLAR</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ mainData.adet_opsiyon}} adet</span>
										</div>
									</div>
								</div>
							</template>

							<Button icon="pi pi-plus" class="p-button-secondary" label="Opsiyon Ekle" @click="OnOpsiyonEkle()" />
							<CrmDataTable :key="CrmDataTable_bm_uniteatacmanlari_key" baseEntityName="bm_uniteatacmanlari" :options="CrmDataTable_bm_uniteatacmanlari_options" :rows="50" :isOzelIslem="true" ozelIslemButtonAdi="Güncelle"
								:isHeaderVisible="true" :doubleClickOpenEntity="false" @onRowSelect="onRowSelect_bm_uniteatacmanlari" @onOzelIslem="onOzelIslemm" />
						</TabPanel>

						<!-- YEDEK PARÇA TEKLİF SİPARİŞ -->
						<TabPanel v-if="isYedekParcaTeklifSiparis">
							<template #header>
								<div class="green-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-bars"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">YEDEK PARÇA TEKLİF SİPARİŞ</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ mainData.adet_yedekparcateklifsiparis}} adet</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_bm_yedekparcateklifsiparis_key" baseEntityName="bm_yedekparcateklifsiparis" :options="CrmDataTable_bm_yedekparcateklifsiparis_options" :rows="50"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>

						<!-- SERVİS TALEPLERİ -->
						<TabPanel v-if="isServisTalebiYetki">
							<template #header>
								<div class="cyan-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-bars"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">SERVİS TALEPLERİ</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ mainData.adet_servistalebi }} adet</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_incident_key" baseEntityName="incident" :options="CrmDataTable_incident_options" :rows="50"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>

						<!-- SERVİS RAPORLARI -->
						<TabPanel v-if="isServisTalebiYetki">
							<template #header>
								<div class="cyan-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-bars"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">SERVİS RAPORLARI</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ mainData.adet_servisraporu }} adet</span>
										</div>
									</div>
								</div>
							</template>

							<CrmDataTable :key="CrmDataTable_serviceappointment_key" baseEntityName="serviceappointment" :options="CrmDataTable_serviceappointment_options" :rows="50"
								:isHeaderVisible="true" :doubleClickOpenEntity="true" />
						</TabPanel>


						<!-- DÖKÜMAN YÖNETİMİ -->
						<TabPanel>
							<template #header>
								<div class="orange-bgcolor solid-surface-text-color p-3">
									<div class="flex align-items-center">
										<i class="pi pi-file"></i>
										<h6 class="m-2" :class="{'pl-2': !isRTL, 'pr-2': isRTL }">DÖKÜMAN YÖNETİMİ</h6>
									</div>
									<div class="flex justify-content-between mt-1 flex-wrap">
										<div class="flex flex-column" style="width: 150px;">
											<span class="mb-1 fs-xlarge">{{ mainData.adet_notlar}} adet</span>
										</div>
									</div>
								</div>
							</template>

							<Annotation objectTypeCode="10007" logicalName="bm_unite" :objectId="entityId" ></Annotation>
						</TabPanel>

					</TabView>
				</div>
			</div>
		</div>

		<Dialog v-model:visible="displayTezgahOpsiyonlariGuncelle" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '50vw'}" header="Tezgah Opsiyon" :modal="true" :closable="true" :showHeader="true" :draggable="true">
            <br>
            <div class="p-4">
                <div class="grid">
					<div class="col-6">
						<div class="field p-fluid">
							<label for="durumAciklamasi">Durum Açıklaması</label>
							<Dropdown id="durumAciklamasi" v-model="dataTezgahOpsiyonlari.durumAciklamasi" :options="ops_SM_durumAciklamasi" optionLabel="Value" optionValue="AttributeValue" placeholder="Durum Açıklaması" :showClear="true" />
						</div>
					</div>
					<div class="col-6">
						<div class="field p-fluid">
							<label for="tedarik">Tedarik</label>
							<Dropdown id="tedarik" v-model="dataTezgahOpsiyonlari.tedarik" :options="ops_SM_tedarik" optionLabel="Value" optionValue="AttributeValue" placeholder="Tedarik" :showClear="true" :filter="true" />
						</div>
					</div>
                </div>

				<Button label="Güncelle" class="mt-3 p-button-secondary" icon="pi pi-save" @click="OnSaveCokluOpsiyon" />
            </div>
            
        </Dialog>

		<Dialog v-model:visible="displayTezgahOpsiyonlari" :breakpoints="{'960px': '90vw', '640px': '90vw'}" :style="{width: '90vw'}" header="Tezgah Opsiyon" :modal="true" :closable="true" :showHeader="true" :draggable="true">
            <br>
            <div class="p-4">
                <div class="grid">
                    <div class="col-3">
						<div class="field p-fluid">
							<EntityLookup id="unite" v-model="dataTezgahOpsiyon.uniteName" ref="ops_entity_unite"
								label="Ünite" entityName="bm_unite" nameField="bm_serino" :state="true"
								@itemSelected="ops_uniteSelected = $event" @itemCleared="ops_uniteSelected = null">
							</EntityLookup>
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<EntityLookupPopup id="urun" v-model="dataTezgahOpsiyon.urunName" ref="ops_entity_urun" label="Ürün"
								entityName="product" nameField="name" :state="true" 
								@itemSelected="ops_urunSelected = $event" @itemCleared="ops_urunSelected = null"
								secondNameField="productnumber"
								:CrmDataTable_options="ops_urun_CrmDataTable_options">
							</EntityLookupPopup>
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="durumAciklamasi">Durum Açıklaması</label>
							<Dropdown id="durumAciklamasi" v-model="dataTezgahOpsiyon.durumAciklamasi" :options="ops_SM_durumAciklamasi" optionLabel="Value" optionValue="AttributeValue" placeholder="Durum Açıklaması" :showClear="true" />
						
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="satisTipi">Satış Tipi</label>
							<Dropdown id="satisTipi" v-model="dataTezgahOpsiyon.satisTipi" :options="ops_SM_satisTipi" optionLabel="Value" optionValue="AttributeValue" placeholder="Satış Tipi" :showClear="true" />
						</div>
					</div>
					
					<div class="col-3">
						<div class="field p-fluid">
							<label for="miktar">Miktar</label>
							<InputNumber v-model="dataTezgahOpsiyon.miktar" mode="decimal" :min="0" placeholder="Miktar" showButtons />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="tedarik">Tedarik</label>
							<Dropdown id="tedarik" v-model="dataTezgahOpsiyon.tedarik" :options="ops_SM_tedarik" optionLabel="Value" optionValue="AttributeValue" placeholder="Tedarik" :showClear="true" :filter="true" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="kontrolEdildimi">Kontrol Edildi mi?</label><br>
							<InputSwitch v-model="dataTezgahOpsiyon.kontrolEdildimi" class="mt-3" />
						</div>
					</div>
					<div class="col-3">
						<div class="field p-fluid">
							<label for="kaldirilabilir">Kaldırılabilir</label><br>
							<InputSwitch v-model="dataTezgahOpsiyon.kaldirilabilir" class="mt-3" />
						</div>
					</div>
                </div>

				<Panel header="İlişkili Kayıtlar">
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="siparis" v-model="dataTezgahOpsiyon.siparisName" ref="ops_entity_siparis"
									label="Sipariş" entityName="salesorder" nameField="ordernumber" :state="true"
									@itemSelected="ops_siparisSelected = $event" @itemCleared="ops_siparisSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="teklif" v-model="dataTezgahOpsiyon.teklifName" ref="ops_entity_teklif"
									label="Teklif" entityName="quote" nameField="quotenumber" :state="true"
									@itemSelected="ops_teklifSelected = $event" @itemCleared="ops_teklifSelected = null">
								</EntityLookup>
							</div>
						</div>

						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="yedekParca" v-model="dataTezgahOpsiyon.yedekParcaName" ref="ops_entity_yedekParca"
									label="Yedek Parça" entityName="bm_yedekparca" nameField="bm_yedekparcakodu" :state="true"
									@itemSelected="ops_yedekParcaSelected = $event" @itemCleared="ops_yedekParcaSelected = null">
								</EntityLookup>
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<EntityLookup id="yedekParcaSiparis" v-model="dataTezgahOpsiyon.yedekParcaSiparisName" ref="ops_entity_yedekParcaSiparis"
									label="Yedek Parça Sipariş" entityName="bm_yedekparcateklifsiparis" nameField="bm_teklifbasligi" :state="true"
									@itemSelected="ops_yedekParcaSiparisSelected = $event" @itemCleared="ops_yedekParcaSiparisSelected = null">
								</EntityLookup>
							</div>
						</div>
					</div>
				</Panel>

				<br><br>

				<Panel header="İşlem Logları">
					<div class="grid">
						<div class="col-3">
							<div class="field p-fluid">
								<label for="girisTarihi">Giriş Tarihi</label>
								<Calendar showButtonBar id="girisTarihi" v-model="dataTezgahOpsiyon.girisTarihi" autocomplete="off" :showIcon="true" />
							</div>
						</div>
						<div class="col-3">
							<div class="field p-fluid">
								<label for="guncellemeTarihi">Güncelleme Tarihi</label>
								<Calendar showButtonBar id="guncellemeTarihi" v-model="dataTezgahOpsiyon.guncellemeTarihi" autocomplete="off" :showIcon="true" />
							</div>
						</div>
					</div>
				</Panel>
				
				<Button label="Kaydet" class="mt-3 p-button-secondary" icon="pi pi-save" @click="OnSaveTezgahOpsiyon" />
            </div>
            
        </Dialog>
	</form>
</template>

<script>
import CrmService from "../service/CrmService";
import { useVuelidate } from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";
import user from '../store/user';

export default {
	setup: () => ({ v$: useVuelidate() }),
	data() {
		return {
			crmService: null,
			submitted: false,
			OBJECT_TYPE_CODE: 10007,
			ENTITY_SUBJECT: "Tezgahlar",
			mainData: {},

			displayTezgahOpsiyonlari: false,
			dataTezgahOpsiyon: {},

			displayTezgahOpsiyonlariGuncelle: false,
			dataTezgahOpsiyonlari: {},
			dataSelectedTezgahOpsiyonlariId: {},

			tabviewActiveIndex: 0,
			nestedMenuitems: [
				{
					label: 'Kaydet',
					icon: 'pi pi-fw pi-save',
					command: () => { this.OnSave(false); },
				},
				{
					label: 'Kaydet & Kapat',
					icon: 'pi pi-fw pi-save',
					command: () => { this.OnSave(true); },
				},
				{
                    label: "Sil",
                    icon: "pi pi-fw pi-trash",
                    command: () => { this.OnDelete(); },
                },
				{
					label:'Devre Dışı Bırak',
					icon:'pi pi-fw pi-ban',
					command: () => { this.OnDevreDisiBirak(); },
					visible: () => !this.isDisabled
				},
				{
                    label: "Servis Talebi Ekle",
                    icon: "pi pi-fw pi-plus-circle",
                    command: () => { this.OnServisTalepleriEkle() },
                },
			],

			urun_CrmDataTable_options: {
				searchAttributes: ["productnumber", "name"],
            },

			ops_urun_CrmDataTable_options: {
				searchAttributes: ["productnumber", "name"],
            },

			CrmDataTable_options: {
				searchAttributes: ["bm_siparisnoidname"],
				filterParent: [
					{ 'name': 'bm_uniteid', 'filterOperator': 'eq', 'value': this.entityId }
				],
				customFormat: [
					{ 'logicalName': 'product', 'attributeName' : 'bm_varsayilanfiyat', 'format' : 'n2'},
				],
            },

			CrmDataTable_bm_uniteatacmanlari_key: 0,
			CrmDataTable_bm_uniteatacmanlari_options: {
				searchAttributes: ["productnumber", "name"],
				filterParent: [
					{ 'name': 'bm_uniteid', 'filterOperator': 'eq', 'value': this.entityId }
				],
				forceSavedQueryId: 'B080AAAC-D3AD-479B-BD90-B697C76E576A',
				views: [
					{ 'viewName': 'İlişkili Görünüm', 'savedQueryId': 'B080AAAC-D3AD-479B-BD90-B697C76E576A' },
				],
				preventSystemViews: true,
				preventUserViews: true
            },

			CrmDataTable_bm_yedekparcateklifsiparis_key: 0,
			CrmDataTable_bm_yedekparcateklifsiparis_options: {
				searchAttributes: ["bm_teklifbasligi", "bm_teklifno", "bm_siparisno", "bm_musterigercekidname", "bm_urunidname", "bm_uniteidname"],
				filterParent: [
					{ 'name': 'bm_uniteid', 'filterOperator': 'eq', 'value': this.entityId }
				],
				forceSavedQueryId: '85EC6CFB-AC75-E211-9AD9-E61F135C9177',
				views: [
					{ 'viewName': '5 - Tümü', 'savedQueryId': '85EC6CFB-AC75-E211-9AD9-E61F135C9177' },
				],
				preventSystemViews: true,
				preventUserViews: true
            },

			CrmDataTable_incident_key: 0,
			CrmDataTable_incident_options: {
				searchAttributes: ["customeridname", "bm_uniteidname", "productidname", "title", "ticketnumber"],
				filterParent: [
					{ 'name': 'bm_uniteid', 'filterOperator': 'eq', 'value': this.entityId }
				],
				forceSavedQueryId: '00000000-0000-0000-00AA-000010001030'
            },

			CrmDataTable_serviceappointment_key: 0,
			CrmDataTable_serviceappointment_options: {
				searchAttributes: ["bm_kod", "bm_servistalebiidname", "bm_uniteidname", "bm_firmaidname", "bm_urunidname", "subject"],
				filterParent: [
					{ 'name': 'bm_uniteid', 'filterOperator': 'eq', 'value': this.entityId }
				],
				forceSavedQueryId: '36C60CBD-AFB1-4217-9211-8189DBF5C07C',
				views: [
					{ 'viewName': 'Tüm Servis Raporları', 'savedQueryId': '36C60CBD-AFB1-4217-9211-8189DBF5C07C' },
				],
            },

			isWritePermission: null
		}
	},
	async created() {
		this.crmService = new CrmService();
		window.addEventListener('scroll', this.handleScroll);

		console.log('created');
		this.OnLoad();
	},
	computed: {
		entityId() {
			return this.$route.params.id;
		},
		profileData() {
			return this.$store.getters.getProfile;
		},
		/*
		isDisabled: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == 'Tezgahlar');
					if (filtered.length > 0) {
						return filtered[0].isWrite == false;
					}
				}
			}

			return true;
		},
		*/
		isDisabled: function() {
			if (this.isYazmaYetkisiVarmi == false) {
				return true;
			}

			if (this.mainData["statecode"]) {
				if (this.mainData["statecode"] == 1) {
					return true;
				}
			}

			return false;
		},
		isYazmaYetkisiVarmi: function() {
			if (this.profileData) {
				if (this.profileData.moduller) {
					const filtered = this.profileData.moduller.filter(x => x.name == this.ENTITY_SUBJECT);
					if (filtered.length > 0) {
						return filtered[0].isWrite == true;
					}
				}
			}

			return false;
		},
		isServisTalebiYetki(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Servis Talepleri');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		isYedekParcaTeklifSiparis(){
			if (this.profileData) {
				const yetkisiVarmi = user.checkPermissionModul(this.profileData, 'Y.P. Teklif / Sipariş');
				if (yetkisiVarmi == true) {
					return true;
				}
				else {
					return false;
				}
			}
			else {
				return false;
			}
		},
		SM_urunmarka: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_urunmarka');
		},
		SM_UrunTipi: function() {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_uruntipi');
		},
		SM_durumAciklamasi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'statuscode');
		},
		SM_tezgahTuru: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_tezgahturu');
		},
		SM_magazinTutucuTipi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_tutucutipi');
		},
		SM_mensei: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_menseisi');
		},
		SM_ihracatizni: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ihracatizniliste');
		},
		SM_ihracatIzniDurum: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_ihracatizni');
		},
		SM_tesvikVarmi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_tesvikvarmi');
		},
		SM_leasing: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_leasing');
		},
		SM_odeme: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_odeme');
		},
		SM_varisGumrugu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_varisgumrugu');
		},
		SM_bolge: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_firmabolge');
		},
		SM_statu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_statu');
		},
		SM_turu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_turu', 'bool');
		},
		SM_ikinciElDurumu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_2eldurumu', 'bool');
		},
		SM_tezgahTipi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_tezgahtipi');
		},
		SM_kullandigiYag: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_kullandigiyag');
		},
		SM_bakimYapildimi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_bakimyapildimi', 'bool');
		},
		SM_bakimSozlesmesiVarmi: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_bakmszlemesivarm', 'bool');
		},
		SM_yuklemeyeri: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_yuklemeyeri');
		},
		SM_durumNotu: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_durumnotu');
		},
		SM_durumBolge: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_durumbolge');
		},
		SM_odemearaci1: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_odemearaci1');
		},
		SM_odemearaci2: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_odemearaci2');
		},
		SM_odemearaci3: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_odemearaci3');
		},
		SM_odemearaci5: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_odemearaci5');
		},
		SM_paraBirimi1: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_parabirimi1');
		},
		SM_paraBirimi2: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_parabirimi2');
		},
		SM_paraBirimi3: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_parabirimi3');
		},
		SM_paraBirimi4: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_parabirimi4');
		},
		SM_paraBirimi5: function () {
			return this.$store.getters.getStringMapByEntityId(this.OBJECT_TYPE_CODE, 'bm_parabirimi5');
		},
		
		firmaSelected: {
			get: function () {
				if (this.mainData["firmaId"]) {
					return { "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["firmaId"] = null;
					this.mainData["firmaName"] = null;
				}
				else {
					this.mainData["firmaId"] = newValue.Value;
					this.mainData["firmaName"] = newValue.Name;
				}
			}
		},
		urunSelected: {
			get: function () {
				if (this.mainData["urunId"]) {
					return { "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["urunId"] = null;
					this.mainData["urunName"] = null;
				}
				else {
					this.mainData["urunId"] = newValue.Value;
					this.mainData["urunName"] = newValue.Name;
				}
			}
		},
		teklifNoSelected: {
			get: function () {
				if (this.mainData["teklifNoId"]) {
					return { "Value": this.mainData["teklifNoId"], "Name": this.mainData["teklifNoName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["teklifNoId"] = null;
					this.mainData["teklifNoName"] = null;
				}
				else {
					this.mainData["teklifNoId"] = newValue.Value;
					this.mainData["teklifNoName"] = newValue.Name;
				}
			}
		},
		siparisNoSelected: {
			get: function () {
				if (this.mainData["siparisNoId"]) {
					return { "Value": this.mainData["siparisNoId"], "Name": this.mainData["siparisNoName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["siparisNoId"] = null;
					this.mainData["siparisNoName"] = null;
				}
				else {
					this.mainData["siparisNoId"] = newValue.Value;
					this.mainData["siparisNoName"] = newValue.Name;
				}
			}
		},
		musteriFaturaSelected: {
			get: function () {
				if (this.mainData["musteriFaturaId"]) {
					return { "Value": this.mainData["musteriFaturaId"], "Name": this.mainData["musteriFaturaName"] }
				} else { return null; }
			},
			set: function (newValue) {
				if (newValue == null) {
					this.mainData["musteriFaturaId"] = null;
					this.mainData["musteriFaturaName"] = null;
				}
				else {
					this.mainData["musteriFaturaId"] = newValue.Value;
					this.mainData["musteriFaturaName"] = newValue.Name;
				}
			}
		},

		//Tezgah Opsiyonları
		ops_uniteSelected: {
			get: function () {
				if (this.dataTezgahOpsiyon["uniteId"]) {
					return { "Value": this.dataTezgahOpsiyon["uniteId"], "Name": this.dataTezgahOpsiyon["uniteName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.dataTezgahOpsiyon["uniteId"] = null;
					this.dataTezgahOpsiyon["uniteName"] = null;
				}
				else {
					this.dataTezgahOpsiyon["uniteId"] = newValue.Value;
					this.dataTezgahOpsiyon["uniteName"] = newValue.Name;
				}
			}
		},
		ops_urunSelected: {
			get: function () {
				if (this.dataTezgahOpsiyon["urunId"]) {
					return { "Value": this.dataTezgahOpsiyon["urunId"], "Name": this.dataTezgahOpsiyon["urunName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.dataTezgahOpsiyon["urunId"] = null;
					this.mainData["urunName"] = null;
				}
				else {
					this.dataTezgahOpsiyon["urunId"] = newValue.Value;
					this.dataTezgahOpsiyon["urunName"] = newValue.Name;
				}
			}
		},
		ops_siparisSelected: {
			get: function () {
				if (this.dataTezgahOpsiyon["siparisId"]) {
					return { "Value": this.dataTezgahOpsiyon["siparisId"], "Name": this.dataTezgahOpsiyon["siparisName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.dataTezgahOpsiyon["siparisId"] = null;
					this.dataTezgahOpsiyon["siparisName"] = null;
				}
				else {
					this.dataTezgahOpsiyon["siparisId"] = newValue.Value;
					this.dataTezgahOpsiyon["siparisName"] = newValue.Name;
				}
			}
		},
		ops_teklifSelected: {
			get: function () {
				if (this.dataTezgahOpsiyon["teklifId"]) {
					return { "Value": this.dataTezgahOpsiyon["teklifId"], "Name": this.dataTezgahOpsiyon["teklifName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.dataTezgahOpsiyon["teklifId"] = null;
					this.dataTezgahOpsiyon["teklifName"] = null;
				}
				else {
					this.dataTezgahOpsiyon["teklifId"] = newValue.Value;
					this.dataTezgahOpsiyon["teklifName"] = newValue.Name;
				}
			}
		},
		ops_yedekParcaSelected: {
			get: function () {
				if (this.dataTezgahOpsiyon["yedekParcaId"]) {
					return { "Value": this.dataTezgahOpsiyon["yedekParcaId"], "Name": this.dataTezgahOpsiyon["yedekParcaName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.dataTezgahOpsiyon["yedekParcaId"] = null;
					this.dataTezgahOpsiyon["yedekParcaName"] = null;
				}
				else {
					this.dataTezgahOpsiyon["yedekParcaId"] = newValue.Value;
					this.dataTezgahOpsiyon["yedekParcaName"] = newValue.Name;
				}
			}
		},
		ops_yedekParcaSiparisSelected: {
			get: function () {
				if (this.dataTezgahOpsiyon["yedekParcaSiparisId"]) {
					return { "Value": this.dataTezgahOpsiyon["yedekParcaSiparisId"], "Name": this.dataTezgahOpsiyon["yedekParcaSiparisName"] }
				} else{ return null; }
			},
			set: function(newValue) {
				if (newValue == null) {
					this.dataTezgahOpsiyon["yedekParcaSiparisId"] = null;
					this.dataTezgahOpsiyon["yedekParcaSiparisName"] = null;
				}
				else {
					this.dataTezgahOpsiyon["yedekParcaSiparisId"] = newValue.Value;
					this.dataTezgahOpsiyon["yedekParcaSiparisName"] = newValue.Name;
				}
			}
		},
		ops_SM_durumAciklamasi: function () {
			return this.$store.getters.getStringMapByEntityId(10008, 'statuscode');
		},
		ops_SM_satisTipi: function () {
			return this.$store.getters.getStringMapByEntityId(10008, 'bm_satistipi', 'bool');
		},
		ops_SM_tedarik: function () {
			return this.$store.getters.getStringMapByEntityId(10008, 'bm_tedarik');
		},
	},
	methods: {
		async OnLoad(){

			if (this.entityId != null) {
				let loader = this.$loading.show({
					container: this.$refs.smsContainer
				});
				
				try {
					
					let data = await this.crmService.getTezgahById(this.entityId);
					if (data.yetkiVarmi == false) {
						this.$router.replace({ name: 'accessdenied' });
					}
					
					if (data.jsonData.length > 0) {
						this.mainData = data.jsonData[0];

						this.CrmDataTable_options.filterParent[0].value = this.entityId;


						this.CrmDataTable_bm_uniteatacmanlari_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_uniteatacmanlari_key++;
						
						this.CrmDataTable_bm_yedekparcateklifsiparis_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_bm_yedekparcateklifsiparis_key++;

						this.CrmDataTable_incident_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_incident_key++;
						
						this.CrmDataTable_serviceappointment_options.filterParent[0].value = this.entityId;
						this.CrmDataTable_serviceappointment_key++;

						const pageHistoryItem = { entityLetter: 'Tez', entityName: 'bm_unite', ID: this.entityId, title: this.mainData.seriNo + '\r\n\r\n' + this.mainData.firmaName };
						this.$store.commit('SET_PAGEHISTORY', pageHistoryItem);

						if (this.mainData["firmaId"]) {
							this.$refs.entity_firma.setDefaultData({ "Value": this.mainData["firmaId"], "Name": this.mainData["firmaName"] });
						}

						if (this.mainData["urunId"]) {
							this.$refs.entity_urun.setDefaultData({ "Value": this.mainData["urunId"], "Name": this.mainData["urunName"] });
						}

						if (this.mainData["teklifNoId"]) {
							this.$refs.entity_teklifNo.setDefaultData({ "Value": this.mainData["teklifNoId"], "Name": this.mainData["teklifNoName"] });
						}

						if (this.mainData["siparisNoId"]) {
							this.$refs.entity_siparisNo.setDefaultData({ "Value": this.mainData["siparisNoId"], "Name": this.mainData["siparisNoName"] });
						}

						if (this.mainData["kurulumTarihi"]) {
							this.mainData["kurulumTarihi"] = new Date(this.mainData.kurulumTarihi);
						}

						if (this.mainData["siparisTarihiSip"]) {
							this.mainData["siparisTarihiSip"] = new Date(this.mainData.siparisTarihiSip);
						}

						if (this.mainData["garantiBitisTarihi"]) {
							this.mainData["garantiBitisTarihi"] = new Date(this.mainData.garantiBitisTarihi);
						}

						if (this.mainData["silahYazisi"]) {
							this.mainData["silahYazisi"] = new Date(this.mainData.silahYazisi);
						}

						if (this.mainData["ihracatIzniGonderme"]) {
							this.mainData["ihracatIzniGonderme"] = new Date(this.mainData.ihracatIzniGonderme);
						}

						if (this.mainData["ceGonderme"]) {
							this.mainData["ceGonderme"] = new Date(this.mainData.ceGonderme);
						}

						if (this.mainData["ihracatIzniOnay"]) {
							this.mainData["ihracatIzniOnay"] = new Date(this.mainData.ihracatIzniOnay);
						}

						if (this.mainData["odemeTarihi1"]) {
							this.mainData["odemeTarihi1"] = new Date(this.mainData.odemeTarihi1);
						}

						if (this.mainData["odemeTarihi2"]) {
							this.mainData["odemeTarihi2"] = new Date(this.mainData.odemeTarihi2);
						}

						if (this.mainData["odemeTarihi3"]) {
							this.mainData["odemeTarihi3"] = new Date(this.mainData.odemeTarihi3);
						}

						if (this.mainData["odemeTarihi4"]) {
							this.mainData["odemeTarihi4"] = new Date(this.mainData.odemeTarihi4);
						}

						if (this.mainData["odemeTarihi5"]) {
							this.mainData["odemeTarihi5"] = new Date(this.mainData.odemeTarihi5);
						}
						
						if (this.mainData["akreditifTarihi"]) {
							this.mainData["akreditifTarihi"] = new Date(this.mainData.akreditifTarihi);
						}

						if (this.mainData["planlananVaris"]) {
							this.mainData["planlananVaris"] = new Date(this.mainData.planlananVaris);
						}

						if (this.mainData["gerceklesenVarisTarihi"]) {
							this.mainData["gerceklesenVarisTarihi"] = new Date(this.mainData.gerceklesenVarisTarihi);
						}

						if (this.mainData["sozlesmeYuklemeTarihi"]) {
							this.mainData["sozlesmeYuklemeTarihi"] = new Date(this.mainData.sozlesmeYuklemeTarihi);
						}

						if (this.mainData["planlananYukleme"]) {
							this.mainData["planlananYukleme"] = new Date(this.mainData.planlananYukleme);
						}

						if (this.mainData["gerceklesenVarisTarihi"]) {
							this.mainData["gerceklesenVarisTarihi"] = new Date(this.mainData.gerceklesenVarisTarihi);
						}

						if (this.mainData["gumrukCikisTarihi"]) {
							this.mainData["gumrukCikisTarihi"] = new Date(this.mainData.gumrukCikisTarihi);
						}

						if (this.mainData["gerceklesenYuklemeTarihi"]) {
							this.mainData["gerceklesenYuklemeTarihi"] = new Date(this.mainData.gerceklesenYuklemeTarihi);
						}

						if (this.mainData["sayfaGuncelleme"]) {
							this.mainData["sayfaGuncelleme"] = new Date(this.mainData.sayfaGuncelleme);
						}

						if (this.mainData["musteriyeTeslimEdilecekTarih"]) {
							this.mainData["musteriyeTeslimEdilecekTarih"] = new Date(this.mainData.musteriyeTeslimEdilecekTarih);
						}

						if (this.mainData["bakimBitisi"]) {
							this.mainData["bakimBitisi"] = new Date(this.mainData.bakimBitisi);
						}

						if (this.mainData["musteriFaturaId"]) {
							this.$refs.entity_musteriFatura.setDefaultData({ "Value": this.mainData["musteriFaturaId"], "Name": this.mainData["musteriFaturaName"] });
						}
					}
				} catch (error) {
					console.log(error);
				}
				finally {
					loader.hide();
				}
			}
		},
		async OnSave(kapat) {
			debugger;

			try {
				this.mainData["entityId"] = this.entityId;
				const response = await this.crmService.getTezgahUpdate(this.mainData);
				debugger;
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
						if (window.opener) {
							window.opener.window.postMessage({'tag': 'refresh'}, window.location.origin);
						}
						
						if (kapat) {
							setTimeout(() => {
								window.close();
							}, 1000);
						}
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
			}
		},
		async OnDelete(){
            this.deleteItem('bm_unite', this.entityId);
        },
		async onRowSelect_bm_uniteatacmanlari(item) {
			this.displayTezgahOpsiyonlari = true;

			let loader = this.$loading.show({
                container: this.$refs.smsContainer
            });

            if (item.entityId != null) {
                try {
                    debugger;
                    let data = await this.crmService.getTezgahOpsiyonlariById(item.entityId);
                    if (data.jsonData.length > 0) {
                        this.dataTezgahOpsiyon = data.jsonData[0];
						this.dataTezgahOpsiyon["entityId"] = item.entityId;
						this.dataTezgahOpsiyon["crudType"] = "update";

						if (this.dataTezgahOpsiyon["uniteId"]) {
							this.$refs.ops_entity_unite.setDefaultData({ "Value": this.dataTezgahOpsiyon["uniteId"], "Name": this.dataTezgahOpsiyon["uniteName"] });
						}

						if (this.dataTezgahOpsiyon["urunId"]) {
							this.$refs.ops_entity_urun.setDefaultData({ "Value": this.dataTezgahOpsiyon["urunId"], "Name": this.dataTezgahOpsiyon["urunName"] });
						}

						if (this.dataTezgahOpsiyon["siparisId"]) {
							this.$refs.ops_entity_siparis.setDefaultData({ "Value": this.dataTezgahOpsiyon["siparisId"], "Name": this.dataTezgahOpsiyon["siparisName"] });
						}

						if (this.dataTezgahOpsiyon["teklifId"]) {
							this.$refs.ops_entity_teklif.setDefaultData({ "Value": this.dataTezgahOpsiyon["teklifId"], "Name": this.dataTezgahOpsiyon["teklifName"] });
						}

						if (this.dataTezgahOpsiyon["yedekParcaId"]) {
							this.$refs.ops_entity_yedekParca.setDefaultData({ "Value": this.dataTezgahOpsiyon["yedekParcaId"], "Name": this.dataTezgahOpsiyon["yedekParcaName"] });
						}

						if (this.dataTezgahOpsiyon["yedekParcaSiparisId"]) {
							this.$refs.ops_entity_yedekParcaSiparis.setDefaultData({ "Value": this.dataTezgahOpsiyon["yedekParcaSiparisId"], "Name": this.dataTezgahOpsiyon["yedekParcaSiparisName"] });
						}

						if (this.dataTezgahOpsiyon["girisTarihi"]) {
                            this.dataTezgahOpsiyon["girisTarihi"] = new Date(this.dataTezgahOpsiyon.girisTarihi);
                        }

						if (this.dataTezgahOpsiyon["guncellemeTarihi"]) {
                            this.dataTezgahOpsiyon["guncellemeTarihi"] = new Date(this.dataTezgahOpsiyon.guncellemeTarihi);
                        }
                    }
                }
                catch (error) {
                    console.log(error);
                }
                finally {
                    loader.hide();
                }
            }
            else {
                loader.hide();
            }
		},
		async OnSaveTezgahOpsiyon(){
			let loader = this.$loading.show({
                container: this.$refs.smsContainer
            });

			try {
				const response = await this.crmService.getTezgahOpsiyonlariCreateUpdate(this.dataTezgahOpsiyon);
				debugger;
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
						setTimeout(() => {
							this.OnLoad();
						}, 500);
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'Eksik alanlar var.', detail: error.message, life: 3500});
			}
			finally {
				this.displayTezgahOpsiyonlari = false;
				loader.hide();
			}
		},
		OnDevreDisiBirak() {
			const postData = {
				logicalName: 'bm_unite',
				entiyId: this.entityId,
				stateCode: 1,
				statusCode: 2
			};

			this.devreDisiItem(postData);
		},
		handleScroll() {
			const el = document.getElementById('stickBar');

			if (window.scrollY > 50) {
				el.style.boxShadow = '0px 2px 1px rgba(0, 0, 0, 0.09), 0px 4px 2px rgba(0, 0, 0, 0.09), 0px 8px 4px rgba(0, 0, 0, 0.09), 0px 16px 8px rgba(0, 0, 0, 0.09), 0px 32px 16px rgba(0, 0, 0, 0.09)';
			}
			else {
				el.style.boxShadow = '';
			}
		},
		getCheckPrivilage(modulName){
			return this.checkPrivilage(this.profileData, modulName);
		},
		onOzelIslemm(data){
			this.displayTezgahOpsiyonlariGuncelle = true;
			this.dataTezgahOpsiyonlariGuncelle = data;
		},
		async OnSaveCokluOpsiyon(){
			try {
				const entityIdList = this.dataTezgahOpsiyonlariGuncelle.map(obj => obj.entityId);
				const postData = {
					tedarik : this.dataTezgahOpsiyonlari.tedarik,
					durumAciklamasi : this.dataTezgahOpsiyonlari.durumAciklamasi,
					entityIdList: entityIdList
				};

				const response = await this.crmService.updateOpsiyonlar(postData);
				if (response) {
					if (response.hata == true) {
						this.$toast.add({severity:'error', summary: 'Kaydedilemedi', detail: response.hataAciklamasi, life: 5000});
					}else {
						this.$toast.add({severity:'success', summary: 'Bilgi', detail:'Başarıyla Kaydedildi', life: 3000});
						setTimeout(() => {
							this.dataTezgahOpsiyonlariGuncelle= [];
							this.dataTezgahOpsiyonlari = {};
							this.displayTezgahOpsiyonlariGuncelle = false;
							this.OnLoad();
						}, 1000);
					}
				}
			} catch (error) {
				this.$toast.add({severity:'error', summary: 'HATA', detail: error.message, life: 3500});
			}
		},
		OnOpsiyonEkle(){
			this.dataTezgahOpsiyon = {};
			this.dataTezgahOpsiyon["crudType"] = "create";
			this.dataTezgahOpsiyon["uniteId"] = this.entityId;
			this.dataTezgahOpsiyon["uniteName"] = this.mainData["seriNo"];
			this.dataTezgahOpsiyon["miktar"] = 1;
			this.dataTezgahOpsiyon["durumAciklamasi"] = 1;
			this.dataTezgahOpsiyon["kontrolEdildimi"] = false;
			this.dataTezgahOpsiyon["kaldirilabilir"] = false;
			this.dataTezgahOpsiyon["satisTipi"] = true;

			if (this.dataTezgahOpsiyon["uniteId"]) {
				setTimeout(() => {
					this.$refs.ops_entity_unite.setDefaultData({ "Value": this.dataTezgahOpsiyon["uniteId"], "Name": this.dataTezgahOpsiyon["uniteName"] });
				}, 500);
			}

			this.displayTezgahOpsiyonlari = true;
		},
		onRowSelect_bm_yedekparcateklifsiparis(data) {
			//this.$router.push({ name: 'incident', params: { id: data.entityId } });

			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'bm_yedekparcateklifsiparis', params: { id: data.entityId } });
			window.open(routeData.href, '_blank', features);
		},
		OnServisTalepleriEkle() {
			let features = 'directories=no,menubar=no,status=no,titlebar=no,toolbar=no';

			let routeData = this.$router.resolve({ name: 'incidentnew_unite', params: { uniteId: this.entityId } });
			window.open(routeData.href, '_blank', features);
		},
		formatNumber(value) {
			debugger;
			if (value != undefined) {
				return value.toLocaleString('tr-TR') + ' ' + this.getCurrency();
			}
		},
		getCurrency() {
			let paraBirimi = '';
			switch (this.mainData.paraBirimiName) {
				case "Dolar":
					paraBirimi = " $"
					break;
				case "Euro":
					paraBirimi = " €"
					break;
				case "İsviçre Frankı":
					paraBirimi = " SFr."
					break;
				case "Türk Lirası":
					paraBirimi = " ₺"
					break;
				case "Yen":
					paraBirimi = " ¥"
					break;
			
				default:
					break;
			}
			return paraBirimi;
		},
	},
	watch: {
		profileData(val) {
			debugger;
			if (val) {
				if (val.moduller) {
					const filtered = val.moduller.filter(x => x.name == 'Tezgahlar');
					if (filtered.length == 0) {
						this.$router.replace({ name: 'accessdenied' });
					}
					else {
						console.log('filtered');
						console.log(filtered);
						debugger;
						setTimeout(() => {
							this.isWritePermission = filtered[0].isWrite;
						}, 50);
					}
				}
			}
		}
	},
	validations() {
		return {
			mainData: {
				firmaAdi: {
					required: helpers.withMessage('Adı alanı dolu olmalıdır!', required),
				},
				telefon1: {
					required: helpers.withMessage('Soyadı alanı dolu olmalıdır!', required),
				},
				epostaAdresi1: {
					email: helpers.withMessage('Geçerli bir mail adresi giriniz!', email),
				},
			},
		}
	}
}
</script>

<style lang="scss" scoped>
	[class^="col-"] {
		flex: 0 0 calc(100%); /* Mobilde tam genişlikte, kenar boşlukları çıkarmak için */
		max-width: calc(100%); /* Mobilde tam genişlikte, kenar boşlukları çıkarmak için */
	}

	@media (min-width: 768px) {
		[class^="col-"] {
			flex: 0 0 calc((var(--col) / 12 * 100%)); /* Masaüstünde belirtilen yüzde genişlikte, kenar boşlukları çıkarmak için */
			max-width: calc((var(--col) / 12 * 100%)); /* Masaüstünde belirtilen yüzde genişlikte, kenar boşlukları çıkarmak için */
		}

		.col-3 { --col: 3; }
		.col-4 { --col: 4; }
		.col-6 { --col: 6; }
	}
</style>
